.home-subscribes {
    cursor: pointer;
    display: flex;
    transition: 0.3s;
    align-items: center;
    padding-top: 16px;
    padding-left: 32px;
    border-radius: 56px;
    margin-right: 12px;
    margin-top: 10px;
    padding-right: 32px;
    flex-direction: row;
    padding-bottom: 16px;
    justify-content: flex-start;
    background-color: #e3ec80;
  }

  .home-subscribe {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    border-radius: 56px;
    margin: 10px 12px 0 0;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
  }
  
  .home-subscribe::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #9400d3);
    background-size: 600% 600%; /* Larger size for the gradient */
    animation: floatingColors 10s infinite linear;
    z-index: 0; /* Set higher z-index to ensure it's behind the text */
  }
  
  @keyframes floatingColors {
    0% {
      background-position: 0% 50%; /* Start from left */
    }
    100% {
      background-position: 100% 50%; /* End at right */
    }
  }
      
    .home-textinput {
        width: 100%;
        outline: none;
        font-size: 25px;
        border-color: none;
        border-radius: 100px;
        background-color:#c8e5f5 ;
        
    }

    .home-text114 {
        color: #4102d4;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        padding: 0px 30px 0px 45px;
        z-index: 1;
    }

    .home-text116 {
        color: #169e04;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        padding: 0px 30px 0px 45px;
        z-index: 1;
    }
    
    
    