.app {
    width: 100vw; /* 100% of the viewport width */
    height: 100vh; /* 100% of the viewport height */
    overflow: hidden; /* Hide any overflow content */
}

.fullscreen-iframe {
    width: 100%;
    height: 100%;
    border: none; /* Optionally, remove iframe border */
}
