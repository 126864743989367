

.home-text116 {
  color: #169e04;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 0px 30px 0px 45px;
  z-index: 1;
}
  
  .stock-market-background {
    height: 100px;
    width: 100vw; /* Set width to cover the entire viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5efbd; /* Initial background color */
    background-size: cover; /* Ensure the background covers the entire screen */
    background-position: center; /* Center the background image */
}
  
  .circle {
    border-radius: 50%;
    position: absolute;
    animation: floatAnimation 20s infinite alternate ease-in-out; /* Float animation */
    box-shadow: 0 2px 8px rgba(10, 26, 54, 0.9); /* Add shadow effect */
  }
  
  @keyframes floatAnimation {
    0% {
      transform: translate(-100%, 100%); /* Start position */
    }

    25% {
      transform: translate(calc(2500% + 2000vw * calc(random() - 3)), calc(100% * calc(random() + 70)));
    }

    50% {
      transform: translate(calc(-1500% + -2000vw * calc(random() - 0.5)), calc(-4100% * calc(random() - 20)));
    }

    75% {
      transform: translate(calc(2000% + 1200vw * calc(random() + 4)), calc(1200% * calc(random() + 5)));
    }

    100% {
      transform: translate(calc(-500% - 2000vw * calc(random() - 0.5)), calc(-1000% * calc(random() + 80))); /* End position */
    }
  }
  